<template>
    <header id="tw-header" class="pt-4">
        <!-- <header id="tw-header"> -->
        <!-- <div class="alert-danger px-3 py-2 mb-3 d-flex justify-content-center align-items-center">
            <span><i class="fas fa-exclamation-circle me-2"></i>We apologize for any inconvenience you may be experiencing with the Client Dashboard. If you are experiencing a delay in load times or your dashboard isn’t working properly, it’s recommended to clear your browser cache. You can follow this link for instructions on <a href="https://kinsta.com/knowledgebase/how-to-clear-browser-cache/" target="_blank">how to clear the cache</a></span>
        </div> -->
        <div class="container-fluid">
            <div class="row mb-4">
                <div class="col-md-4 col-lg-3 col-xl-2 d-flex justify-content-center justify-content-md-start">
                    <div id="tw-header-logo" class="d-flex align-items-center me-5">
                        <router-link class="" to="/" exact><img alt="TWD" src="@/assets/images/logo-twd.svg" /></router-link>
                    </div>
                </div>
                <div class="col d-flex mt-3 mt-md-0">
                    <div class="d-flex">
                        <span v-if="siteLogo" id="tw-header-site-logo" class="me-3"><img :src="siteLogo" alt="Site logo" /></span>
                        <span class="flex-column justify-content-around" :class="siteLogo ? 'd-none d-sm-inline-flex' : 'd-inline-flex'">
                            <div id="tw-header-site-name">
                                <span class="fw-bold">{{ siteName }}</span> Website
                            </div>
                            <div id="tw-header-site-visit-link"><a class="tw-blue-color text-decoration-none" :href="siteURL" target="_blank">View Live Site</a></div>
                        </span>
                    </div>
                    <div class="ms-auto d-flex align-items-center position-relative">
                        <span class="d-flex cursor-pointer me-3">
                            <a v-if="hasHeyGov && heygovFeatures.length !== 1 && heygovFeatures[0] !== 'events'" type="button" id="tw-heygov-button" class="btn bg-white" :href="`https://app.heygov.com/${heygovID}`" target="heygov">
                                <img src="https://storage.googleapis.com/files.heygov.com/assets/heygov-logo.svg" width="40" alt="HeyGov logo" /><small id="tw-heygov-button-text"> Open HeyGov</small>
                            </a>
                        </span>
                        <span v-if="userHasPermission('view_dashboard_notifications')" id="tw-header-notifications" class="bg-white position-relative d-flex align-items-center justify-content-center me-3 cursor-pointer" @click="openNotificationsModal">
                            <i class="fas fa-bell"></i>
                            <span v-if="unreadNotificationsCount" id="tw-header-notifications-dot" class="position-absolute tw-red-bg-color rounded-circle"></span>
                        </span>
                        <!-- <div class="d-flex flex-column position-absolute w-25 justify-content-center align-items-center" style="bottom: -25px;">
							<button ref="resetBtn" class="btn btn-sm btn-info p-0 w-50" data-bs-toggle="tooltip" data-bs-placement="top" title="Temporary option for testing only. Click to set notifications as not viewed." @click="resetNotifications"><small>reset</small></button>
						</div> -->
                        <span>
                            <div id="tw-header-user" class="account d-flex bg-white">
                                <span id="tw-header-user-avatar">
                                    <img :src="avatar" :alt="currentUserName" />
                                </span>
                                <span class="dropdown d-flex w-100">
                                    <span class="dropdown-toggle d-flex align-items-center w-100 ps-3" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="tw-header-user-info d-flex align-items-center">
                                            <span class="d-flex justify-content-center flex-column">
                                                <p class="mb-2">{{ currentUserName }}</p>
                                                <small id="tw-last-login-text"
                                                    >Last login: <span>{{ loginTime }}</span></small
                                                >
                                            </span>
                                        </span>
                                        <span class="tw-toggle-angle px-3 ms-auto">
                                            <span class="d-block"></span>
                                        </span>
                                    </span>

                                    <ul class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuLink">
                                        <li v-if="userHasPermission('view_dashboard_account_settings')" class="py-1">
                                            <router-link class="dropdown-item" to="/account-settings/client-info" exact-path>Account Settings</router-link>
                                        </li>
                                        <!-- <li class="py-1">
											<a class="dropdown-item" :href="siteURL + '/wp-admin/profile.php?fl_builder&dashboard-auth=' + auth" target="_blank">Profile</a>
										</li> -->
                                        <li class="py-1">
                                            <a class="dropdown-item" :href="siteURL + '?dashboard-auth=' + auth" target="_blank">Your Website</a>
                                        </li>
                                        <li class="py-1">
                                            <a class="dropdown-item" href="#" @click="logout()">Logout</a>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <Nav v-if="!$route.path.startsWith('/account-settings')" />
        </div>
        <NotificationsModal ref="NotificationsModal" @notificationsViewed="handleNotificationsViewed" />
        <OverdueModal ref="OverdueModal" />
    </header>
</template>

<script>
import userMixin from "@/mixins/userMixin";
import siteMixin from "@/mixins/siteMixin";
import accountSettingsMixin from "@/mixins/accountSettingsMixin";
import heyGovMixin from "../mixins/heyGovMixin";
import Nav from "@/components/Nav.vue";
import NotificationsModal from "@/components/modals/NotificationsModal";
import OverdueModal from "@/components/modals/OverdueModal";
// import { Tooltip } from "bootstrap";
export default {
    name: "Header",
    components: {
        Nav,
        NotificationsModal,
        OverdueModal,
    },
    data: () => ({
        currentUserName: null,
        siteName: null,
        siteURL: null,
        siteLogo: null,
        loginTime: null,
        auth: null,
        unreadNotificationsCount: 0,
        avatar: null,
        hasHeyGov: false,
        heygovID: null,
        heygovFeatures: [],
    }),
    mounted() {
        let userData = this.getCurrentUser();
        let siteData = this.getSite();

        this.checkNotifications();

        // if (true) {
        if (userData) {
            this.siteName = siteData.name;
            this.siteURL = siteData.url;
            this.siteLogo = siteData.logo;
            this.currentUserName = userData.data.name;
            this.auth = userData.auth;
            this.avatar = userData.data.avatar_urls[96];
            this.loginTime = this.moment(userData.data.lastLogin).fromNow();
            // this.setUnreadNotifications();
        }

        //          if (!Tooltip.getInstance(this.$refs.resetBtn)) {
        // 	new Tooltip(this.$refs.resetBtn)
        // }
    },
    created() {
        let userData = this.getCurrentUser();
        if (userData) {
            this.isHeyGov();
        }
    },
    methods: {
        async checkNotifications() {
            this.unreadNotificationsCount = await this.getUnreadedNotificationsCount();
        },
        openNotificationsModal() {
            this.$refs.NotificationsModal.showModal(true);
        },
        handleNotificationsViewed() {
            this.checkNotifications();
        },
        async resetNotifications() {
            await this.setNotificationsUnread();
            this.checkNotifications();
        },
        async isHeyGov() {

            if (this.getSiteType() === "wordpress") {
                let res = await this.isHeyGovClient();
                this.hasHeyGov = res;
                if (this.hasHeyGov) {
                    let jurisdiction = await this.heyGovJurisdiction();
                    if (jurisdiction) {
                        this.heygovFeatures = jurisdiction.features;
                    }
                    this.getHeyGovID();
                }
            } else {
                let siteData = this.getSite();
                if (siteData) {
                    this.hasHeyGov = siteData.hg_client;
                    if (this.hasHeyGov) {
                        this.heygovFeatures = [1,2]; // TODO: get features from HeyGov
                        this.getHeyGovID();
                    }
                }
            }
        },
        async getHeyGovID() {
            if (this.getSiteType() === "wordpress") {
                let data = await this.axios.get(`${this.siteURL}/wp-json/heygov/v1/info`);
                if (data.status && data.status === 200) {
                    this.heygovID = data.data.heygov_id;
                }
            } else {
                let userData = this.getCurrentUser();
                if (userData) {
                    this.heygovID = userData.data.heygov_jurisdiction;
                }
            }
        },
    },
    mixins: [userMixin, siteMixin, accountSettingsMixin, heyGovMixin],
};
</script>
