<!-- Modal -->
<template>
    <div class="modal fade" id="NotificationsModal" ref="NotificationsModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header tw-red-bg-color text-white">
                    <div class="d-flex">
                        <span class="d-inline-flex">
                            <div class="row">
                                <h5 class="modal-title col-12" id="modal-title">Notifications</h5>
                                <p class="col-12 mb-0">Here you can see all notifications</p>
                            </div>
                        </span>
                    </div>
                    <button type="button" class="btn-close bg-white me-2 rounded-circle" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive position-relative">
                        <table ng-if="!vm.permissions.notices" st-table="vm.results" st-pipe="vm.loadResults" class="tw-table table table-hover mb-5">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th style="min-width: 450px;width: 450px;">Subject</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody class="position-relative">
                                <div v-if="!requestResolved" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
                                    <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                </div>
                            <!-- <tbody v-if="posts && posts.length"> -->
                                <tr v-for="(notification, index) in notifications" :key="index" class="cursor-pointer" :class="(notification.viewed === 0) && 'fw-bold'">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ notification.title }}</td>
                                    <td>{{ notification.body }}</td>
                                    <td class="text-nowrap">{{ moment(notification.updated_at).format('ll') }}</td>
                                   
                                </tr>
                            </tbody>

                            <!-- <tbody v-if="!requestResolved && (!posts || !posts.length)">
                                <tr>
                                    <td colspan="6" class="text-center">Loading <i class="fa fa-spinner fa-spin"></i></td>
                                </tr>
                            </tbody> -->

                            <tbody v-if="requestResolved && (!notifications || !notifications.length)">
                                <tr>
                                    <td colspan="6" class="text-center">Nothing to show, no results found.</td>
                                </tr>
                            </tbody>

                            <!-- <tfoot v-if="mediaLibrary.length">


                                <tr>
                                    <td colspan="4" class="tw-table-pagination text-end">

                                        <Pagination
                                            :totalItems="numberOfMedia"
                                            :currentPage="page"
                                            :perPage="10"
                                            @pageChanged="setPage"
                                            :goButton="false"
                                            styled="centered"
                                            borderActiveColor=""
                                            borderTextActiveColor="text-white"
                                        />
                                    </td>
                                </tr>
                            </tfoot> -->
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { Modal } from 'bootstrap';
    import accountSettingsMixin from '@/mixins/accountSettingsMixin';
    export default {
        name: 'NotificationsModal',
        emits: ["notificationsViewed"],
        data: () => ({
            notifications: [],
            modal: null,
            toast: null,
            page: 1,
            subject: '',
            message: '',
            spinner: false,
            sendError: false,
            requestResolved: false,
        }),
        mounted() {
            this.modal = new Modal(this.$refs.NotificationsModal)

            // this.setNotifications();
            // this.showToast()
            let NotificationsModal = document.getElementById('NotificationsModal')
            let self = this;
            NotificationsModal.addEventListener('hidden.bs.modal', function () {
                // self.subject = '';
                // self.message = '';
                self.notifications = [];
                self.requestResolved = false;
            });

        },
        methods: {
            showModal: async function(value) {
                if (value) {
                    await this.setNotifications();
                    this.modal.show();

                    this.notificationsSetViewed();
                    
                }
            },
            async setNotifications() {

                this.requestResolved = false;
                let res = await this.getNotifications();

                this.notifications = res;

                this.requestResolved = true;
            },
            async notificationsSetViewed() {
                let ids = this.prepareIdsString();
                if (ids) {
                    let res = await this.setNotificationsViewed(ids);
                    
                    if (res) {
                        this.$emit('notificationsViewed');
                    }
                }
            },
            prepareIdsString() {
                let ids = [];
                this.notifications.forEach(function(notification){
                    if (!notification.viewed) {
                        ids.push(notification.id);
                    }
                })
                if (ids.length) {
                    return JSON.stringify(ids);
                } else {
                    return null;
                }
            },
            setPage(n) {
                this.page = n;
            },
        },
        mixins: [accountSettingsMixin]
    }
</script>
<style scoped>
    input, textarea {
        border-radius: 21.5px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #CCD1D8 !important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: #CCD1D8 !important;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: #CCD1D8 !important;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #CCD1D8 !important;
    }
</style>