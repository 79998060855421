<template>
    <div v-show="show" id="tw-overlay" class="position-absolute top-0 bottom-0 start-0 end-0">
        
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
	export default {
        name: 'Overlay',
        data: () => ({
            show: false
        }),
        mounted() {
            
        },
        watch: {
            overlayShow(val) {
                this.show = val;
            }
        },
        computed: {
            ...mapGetters([
                'overlayShow',
            ])
        },
    }
</script>
<style scoped>
    #tw-overlay {
        z-index: 5;
        background: rgba(83, 79, 79, 0.2);
        backdrop-filter: blur(4px);
    }
</style>