export default {
    created() {
        const methods = Object.keys(this.$options.methods || {});

        // loop through all methods to check which one is protected by permission
        methods.forEach((key) => {
            const originalMethod = this[key];
            this[key] = (...args) => {
                // Check if there are permissions for this method and set permission header before calling the original method
                if (this.methodPermissions[key]) {

                    this.headers = {...this.headers, ...{'X-Required-Permission': this.methodPermissions[key]}}
                }


                // Call the original method
                return originalMethod.apply(this, args);
            };
        });
    }
};
