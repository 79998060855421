import { Tooltip } from 'bootstrap';
import userMixin from '@/mixins/userMixin';

export const permission = {
    mounted(el, binding) {
        if (binding.value) {

            let { juniper, wp, tooltipTitle, supports, tooltip, model } = binding.value;
            const site = localStorage.getObject("site");

            if (typeof supports === 'undefined') {
                supports = true;
            }

            if (!tooltipTitle && !tooltip) {
                tooltipTitle = '<h4><i class="fas fa-lock"></i> Locked</h4><p class="text-center"><small>Locked for current user.</small></p>';
            } else if (!tooltipTitle) {
                tooltipTitle = 'Locked for current user.';
            }

            if (!supports || ((juniper || wp) && site && !userMixin.methods.userHasPermission(juniper, wp, site.type, model))) {

                if (!supports && !tooltip) {
                    tooltipTitle = '<h4><i class="fas fa-lock"></i> Locked</h4><p class="text-center"><small>Locked for yor site current theme. You need to upgrade theme to get this functioning.</small></p>';
                } else if (!supports) {
                    tooltipTitle = 'Locked for yor site current theme. You need to upgrade theme to get this functioning.';
                }

                // add overlay
                const overlay = document.createElement("div");
                overlay.classList.add('tw-disabled-component-overlay');
                el.classList.add('tw-disabled-component');
                el.append(overlay);

                // disable all links,buttons and inputs
                const elements = el.querySelectorAll('a', 'input', 'button');
                elements.forEach((element) => {
                    element.classList.add('disabled');
                    element.setAttribute('disabled', true);
                    // link.innerHTML = link.text + '<span class="ms-2"><i class="fas fa-lock"></i></span>';
                })

                if(tooltip) {
                    el.setAttribute('data-bs-toggle', 'tooltip');
                    el.setAttribute('title', tooltipTitle);
                    // const lockIcon = document.createElement('i');
                    // lockIcon.classList.add('fas', 'fa-lock');
                    // overlay.classList.add('justify-content-end');
                    // overlay.append(lockIcon);
                    new Tooltip(el);
                } else {
                    overlay.classList.add('flex-column');
                    overlay.innerHTML = tooltipTitle;
                }

            }
        }
    },
    updated(el, binding) {
        if (binding.value) {

            let { juniper, wp, tooltipTitle, supports, tooltip, model } = binding.value;
            const site = localStorage.getObject("site");

            if (typeof supports === 'undefined') {
                supports = true;
            }

            if (!tooltipTitle && !tooltip) {
                tooltipTitle = '<h4><i class="fas fa-lock"></i> Locked</h4><p class="text-center"><small>Locked for current user.</small></p>';
            } else if (!tooltipTitle) {
                tooltipTitle = 'Locked for current user.';
            }

            // remove disabled styles before update
            el.classList.remove('tw-disabled-component')
            const existingOverlay = el.querySelector('.tw-disabled-component-overlay');
            if (existingOverlay) {
                existingOverlay.remove()
            }
            let existingTooltip = Tooltip.getInstance(el);
            if (existingTooltip) {
                existingTooltip.dispose();
                el.removeAttribute('data-bs-toggle');
                el.removeAttribute('title');
            }
            // enable all links,buttons and inputs
            const elements = el.querySelectorAll('a', 'button', 'input');
            elements.forEach((element) => {
                element.classList.remove('disabled');
                element.setAttribute('disabled', false);
                // link.innerHTML = link.text + '<span class="ms-2"><i class="fas fa-lock"></i></span>';
            })

            if (!supports || ((juniper || wp) && site && !userMixin.methods.userHasPermission(juniper, wp, site.type, model))) {

                if (!supports && !tooltip) {
                    tooltipTitle = '<h4><i class="fas fa-lock"></i> Locked</h4><p class="text-center"><small>Locked for yor site current theme. You need to upgrade theme to get this functioning.</small></p>';
                } else if (!supports) {
                    tooltipTitle = 'Locked for yor site current theme. You need to upgrade theme to get this functioning.';
                }

                // add overlay
                const overlay = document.createElement("div");
                overlay.classList.add('tw-disabled-component-overlay');
                el.classList.add('tw-disabled-component');
                el.append(overlay);

                // disable all links,buttons and inputs
                const elements = el.querySelectorAll('a', 'input', 'button');
                elements.forEach((element) => {
                    element.classList.add('disabled');
                    element.setAttribute('disabled', true);
                    // link.innerHTML = link.text + '<span class="ms-2"><i class="fas fa-lock"></i></span>';
                })

                if(tooltip) {
                    el.setAttribute('data-bs-toggle', 'tooltip');
                    el.setAttribute('title', tooltipTitle);
                    // const lockIcon = document.createElement('i');
                    // lockIcon.classList.add('fas', 'fa-lock');
                    // overlay.classList.add('justify-content-end');
                    // overlay.append(lockIcon);
                    new Tooltip(el);
                } else {
                    overlay.classList.add('flex-column');
                    overlay.innerHTML = tooltipTitle;
                }

            }
        }
    },
};
