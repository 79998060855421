export default {
    methods: {
        sliceString(string, length = 35) {
            if (string && string.length > length) {
                return string.substr(0, length) + '..';
            }
            return string;
        },
        getIntervalLabels() {
            return {
                    Daily:      'days',
                    Weekly:     'weeks',
                    Monthly:    'months',
                    Yearly:     'years'
                };
        },
        removeHttp(url) {
            return url.replace(/^https?:\/\//, '');
        },
        decodeHTMLEntities (str) {
            if(str && typeof str === 'string') {
                let element = document.createElement('div');
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }

            return str;
        },
        getDomain(url, subdomain) {
            subdomain = subdomain || false;

            url = url.replace(/(https?:\/\/)?(www.)?/i, '');

            if (!subdomain) {
                url = url.split('.');

                url = url.slice(url.length - 2).join('.');
            }

            if (url.indexOf('/') !== -1) {
                return url.split('/')[0];
            }

            return url;
        }
    }
}