<!-- Modal -->
<template>
    <div class="modal fade" id="loginModal" ref="loginModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div v-if="showCMS6PassResetModal" class="modal-content">
                <form @submit.prevent="sendResetPasswordRequest()">
                    <div class="modal-header tw-red-bg-color text-white">
                        <div>
                            <h5 class="modal-title" id="loginModalLabel">Send reset password request</h5>
                            <p>Enter your email address and click send button</p>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div v-if="loginError" class="alert alert-danger" role="alert" v-html="loginError"></div>
                        <div v-if="resetPasswordSuccessMessage" class="alert alert-success" role="alert" v-html="resetPasswordSuccessMessage"></div>
                        <div class="mb-3">
                            <label for="website" class="form-label">Website</label>
                            <input type="text" class="form-control" v-model="url" id="website" aria-describedby="websiteHelp" @blur="urlCheck" />
                            <div id="websiteHelp" class="form-text">Example: <i>https://example.org</i></div>
                        </div>
                        <div class="mb-3">
                            <label for="reset-password-email" class="form-label">Email</label>
                            <input type="email" class="form-control" v-model="resetPasswordEmail" id="reset-password-email" />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="col">

                            <a
                            @click.prevent="showCMS6PassResetModal = false; loginError = false;"
                            href="#"
                            class=""
                            >
                                Login
                            </a>
                        </div>
                        <button type="submit" class="btn tw-red-bg-color text-white">
                            Send
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                    </div>
                </form>
            </div>
            <div v-else class="modal-content">
                <form @submit.prevent="login()">
                    <div class="modal-header tw-red-bg-color text-white">
                        <div>
                            <h5 class="modal-title" id="loginModalLabel">Login</h5>
                            <p>Login to your Municipality Website Dashboard</p>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div v-if="loginError" class="alert alert-danger" role="alert" v-html="loginError"></div>
                        <div class="mb-3">
                            <label for="website" class="form-label">Website</label>
                            <input type="text" class="form-control" v-model="url" id="website" aria-describedby="websiteHelp" @blur="urlCheck" />
                            <div id="websiteHelp" class="form-text">Example: <i>https://example.org</i></div>
                        </div>
                        <div class="mb-3">
                            <label for="user" class="form-label">User</label>
                            <input type="text" class="form-control" v-model="user" id="user" />
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <div class="input-group">
                                <input class="form-control" v-model="password" id="password" :type="showPassword ? 'text' : 'password'" />
                                <div class="input-group-text">
                                    <span class="cursor-pointer" @click="showPassword = !showPassword">
                                        <span v-show="showPassword">
                                            <i class="far fa-eye-slash"></i>
                                        </span>
                                        <span v-show="!showPassword">
                                            <i class="far fa-eye"></i>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" v-model="remember" id="remember" />
                            <label class="form-check-label" for="remember">Remember me</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="col">
                            <a
                            @click.prevent="showCMS6PassResetModal = true; loginError = false;"
                            href="#"
                            class=""
                            >
                                Password reset
                            </a>
                        </div>
                        <button type="submit" class="btn tw-red-bg-color text-white">
                            Login
                            <span v-if="spinner"><i class="fas fa-spinner fa-spin"></i></span>
                        </button>
                    </div>
                </form>
            </div>



        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import userMixin from "@/mixins/userMixin";
import siteMixin from "@/mixins/siteMixin";
import { mapGetters } from "vuex";
export default {
    name: "LoginModal",
    data: () => ({
        modal: null,
        url: "",
        user: "",
        password: "",
        remember: false,
        spinner: false,
        loginError: false,
        auth: null,
        loginPath: null,
        loginAttempts: 0,
        show: false,
        showCMS6PassReset: false,
        showCMS6PassResetModal: false,
        resetPasswordEmail: "",
        resetPasswordSuccessMessage: "",
        showPassword: false
    }),
    mounted() {
        let params = new URLSearchParams(document.location.search);

        this.url = params.get("url") || "";
        this.user = params.get("user") || "";
        this.password = params.get("password") || "";

        if ((this.url != "") & (this.user != "") & (this.password != "")) {
            this.login(true);
        }

        let user = this.getCurrentUser();
        if (!user || this.show) {
            this.modal = new Modal(this.$refs.loginModal);
            this.modal.show();
            document.body.classList.add("tw-unauthorized");
        }

        let site = localStorage.getObject("site");

        if (this.url === "" && site) {
            this.url = site.url;

            if (this.url.login_path) {
                this.loginPath = this.url.login_path;
            }
        }
    },
    watch: {
        loginModalShow(val) {
            if (val) {
                this.modal = new Modal(this.$refs.loginModal);
                this.modal.show();
                document.body.classList.add("tw-unauthorized");
            }
        },
    },
    methods: {
        async login(auto = false) {
            let self = this;
            self.spinner = true;

            if (!this.url.length) {
                this.spinner = false;
                this.loginError = "Website field cannot be empty.";
                return false;
            }
            if (process.env.NODE_ENV == "production") {
                this.tracker.setUserID(this.user + "|" + this.url);
            }

            this.loginAttempts++;

            this.auth = btoa(this.user + ":" + this.password);

            let authData = { site_url: this.url, password: this.password, login: this.user };

            if (auto) {
                authData = { ...authData, ...{ auto: true } };
            }

            this.axios.post(process.env.VUE_APP_JUNIPER_API + "auth-dashboard", authData, {}).then(async (response) => {
                if (response.data) {
                    if (response.data.type && response.data.type == "error") {
                        let errors = "";

                        for (const [key, error] of Object.entries(response.data.errors)) {
                            key;
                            errors += error + "<br>";
                        }
                        // response.data.errors.forEach(function(error){
                        //     errors += error + '<br>';
                        // })
                        self.spinner = false;
                        this.loginError = errors;
                        this.showCMS6PassReset = true;
                    } else if (response.data.message) {
                        if (response.data.message == "no_site") {
                            this.showCMS6PassReset = false;
                            self.spinner = false;
                            this.loginError = "Couldn't fetch your website data";
                        } else if (response.data.message == "wp_site") {
                            this.showCMS6PassReset = false;
                            this.axios
                                .get(this.url + "/wp-json")
                                .then(
                                    async (response) => {
                                        if (response.data && (typeof response.data !== "object" || response.data === null)) {
                                            this.spinner = false;
                                            this.loginError = "Check your website url and try again.";
                                        }

                                        if ("data" in response && "name" in response.data && response.data.namespaces.indexOf("twd-dashboard/v1") != -1) {
                                            let logoUrl = null;

                                            if (
                                                response.data.site_logo &&
                                                response.data._links &&
                                                response.data._links["wp:featuredmedia"] &&
                                                response.data._links["wp:featuredmedia"][0] &&
                                                response.data._links["wp:featuredmedia"][0].href
                                            ) {
                                                let logoResponse = await this.axios.get(response.data._links["wp:featuredmedia"][0].href);
                                                logoUrl = logoResponse.data.source_url;
                                            }

                                            self.loginPath = response.data.login_path;

                                            localStorage.setObject("site", {
                                                url: response.data.url,
                                                name: response.data.name,
                                                description: response.data.description,
                                                routes: response.data.routes,
                                                supportsFBPublish: response.data.supportsFBPublish,
                                                supportsDepartments: response.data.supportsDepartments,
                                                login_path: response.data.login_path,
                                                logo: logoUrl,
                                                type: "wordpress",
                                                bbActive: response.data.bb_active,
                                                tribeEventsActive: response.data.tribe_events_active,
                                                heygovApiKey: response.data.heygov_api_key,
                                                heygovJurisdiction: response.data.heygov_jurisdiction,
                                                timezone: response.data.timezone,
                                            });
                                            return true;
                                        } else {
                                            this.spinner = false;
                                            this.loginError = "Dashboard is not supported";
                                            return false;
                                        }
                                    },
                                    (error) => {
                                        console.log(this.url);
                                        console.log(error);
                                        if (this.url.includes("://www.")) {
                                            this.spinner = false;
                                            this.loginError = "Could not fetch your website data";
                                        } else {
                                            console.log("Try fix for redirects.");
                                            this.url = this.url.replace("://", "://www.");
                                            this.login();
                                        }
                                    }
                                )
                                .then((response) => {
                                    if (response) {
                                        this.auth = btoa(this.user + ":" + this.password); // btoa base64 encode

                                        this.axios
                                            .get(this.url + "/wp-json/wp/v2/users/me?context=edit&_envelope", {
                                                headers: {
                                                    Authorization: "Basic " + this.auth,
                                                },
                                            })
                                            .then(
                                                (response) => {
                                                    if ("id" in response.data.body || "id" in response.data) {
                                                        response.data.body.lastLogin = this.moment();

                                                        if (this.remember) {
                                                            localStorage.setObject("user", {
                                                                auth: this.auth,
                                                                data: response.data.body,
                                                            });
                                                        } else {
                                                            sessionStorage.setObject("user", {
                                                                auth: this.auth,
                                                                data: response.data.body,
                                                            });
                                                        }
                                                        this.modal.hide();
                                                        window.location.reload();
                                                    } else {
                                                        this.spinner = false;
                                                        this.loginError = "Invalid user or pass";
                                                    }
                                                    // console.log(response)
                                                    //this.spinner = false; // temp here for test
                                                },
                                                () => {
                                                    this.spinner = false;
                                                    this.loginError = "Invalid user or pass";
                                                }
                                            );
                                    }
                                });
                        } else if (response.data.message) {
                            self.spinner = false;
                            this.loginError = response.data.message;
                        }
                    } else if (response.data.access_token) {
                        // console.log('ulogovani smo');

                        // set site data
                        localStorage.setObject("site", {
                            id: response.data.client.id,
                            url: response.data.client.url,
                            staging_url: response.data.client.staging_url || null,
                            name: response.data.client.name,
                            description: response.data.client.description,
                            routes: response.data.client.routes,
                            supportsAlerts: response.data.client.supportsAlerts,
                            supportsFBPublish: response.data.client.supportsFBPublish,
                            supportsDepartments: response.data.client.supportsDepartments,
                            login_path: response.data.client.login_path,
                            logo: response.data.client.logo,
                            type: "cms6",
                            bbActive: false,
                            timezone: response.data.client.timezone,
                            hg_client: response.data.client.hg_client,
                        });

                        // set user data
                        if (this.remember) {
                            localStorage.setObject("user", {
                                auth: response.data.access_token,
                                data: response.data.user,
                            });
                        } else {
                            sessionStorage.setObject("user", {
                                auth: response.data.access_token,
                                data: response.data.user,
                            });
                        }

                        if (!auto) {
                            this.modal.hide();
                        }

                        if (this.loginModalShow) {
                            document.body.classList.remove("tw-unauthorized");
                            this.$store.commit("hideLoginModal");
                        } else {
                            if (auto) {
                                window.location = window.location.href.split("?")[0];
                            } else {
                                window.location.reload();
                            }
                        }
                    }
                }
            });

            return;
        },
        async sendResetPasswordRequest() {
            this.spinner = true;
            let response = await this.axios.post(process.env.VUE_APP_JUNIPER_API + `forgot-password`, {site_url: this.url, email: this.resetPasswordEmail});

            if (response.data.type && response.data.type == "error") {
                let errors = "";

                for (const [key, error] of Object.entries(response.data.errors)) {
                    key;
                    errors += error + "<br>";
                }

                this.loginError = errors;
                this.showCMS6PassReset = true;
            } else if (response.data.status) {
                this.resetPasswordSuccessMessage = response.data.status;
            } else if (response.data.redirect_url) {
                window.open(response.data.redirect_url, "_blank") || window.location.replace(response.data.redirect_url);
            }
            this.spinner = false;
        },
        urlCheck() {
            if (this.url.length == 0) {
                return;
            }
            if (this.url.indexOf("http") < 0) {
                this.url = "https://" + this.url;
            } else if (this.url.indexOf("https") == -1 && this.url.indexOf("localhost") == -1) {
                this.url = "https://" + this.url.substr(7);
            }
            if (this.validURL(this.url) || this.url.indexOf("localhost") != -1) {
                this.loginError = false;
            } else {
                this.loginError = "Not a valid website url";
            }
            // console.log(this.validURL(this.url));
        },
    },
    computed: {
        ...mapGetters(["loginModalShow", "axiosConfigs"]),
    },
    mixins: [userMixin, siteMixin],
};
</script>
