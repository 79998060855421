import siteMixin from "@/mixins/siteMixin";
import userMixin from "@/mixins/userMixin";
import helperMixin from "@/mixins/helperMixin";
export default {
    data: () => ({
        //token: '1hbnPuxxKguJe7HlgIHhIBeWvoVGgJrh',
        apiRoute: process.env.VUE_APP_HEYGOV_API.replace(/\/$/, ""),
        // apiRoute: "https://api.heygov.com",
        // apiRoute: "http://localhost:8080",
        // appRoute: "https://app.heygov.com",
        appRoute: process.env.VUE_APP_HEYGOV_APP,
        // appRoute: "http://localhost:8081",

        suffix: "venues?status=public",
        headers: null,
        controller: null,
        hasHeyGov: false,
        hasHeyGovEvents: false,
    }),
    methods: {
        async getHeyGovVenues() {
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}/${this.suffix}`); // action additional param
            // let res = await this.axios.post(this.apiRoute + this.site + this.suffix, {token: this.token, action: 'subscriptionInfo' }); // action additional param
            if (res.status && res.status === 200) {
                return res.data;
            }
        },
        async heyGovJurisdiction() {
            if (this.domain) {
                let res = await this.axios.get(`${this.apiRoute}/${this.domain}`);
                let jurisdiction = res.data;
                let site = localStorage.getObject("site");
                if (jurisdiction) {
                    this.hasHeyGov = true;
                    if (jurisdiction?.features.includes("events") && !site.tribeEventsActive) {
                        this.hasHeyGovEvents = true;
                    } else {
                        this.hasHeyGovEvents = false;
                    }
                }
                if (this.getSiteType() == "cms6") {
                    this.hasHeyGovEvents = true;
                }
                return jurisdiction;
            }
        },
    },
    computed: {
        domain() {
            let user = this.getCurrentUser();

            let domain;

            if (user.data.heygov_jurisdiction) {
                domain = user.data.heygov_jurisdiction;
            } else {
                let domainData = localStorage.getObject("site");
                domain = domainData.heygovJurisdiction;
            }

            if (this.getSiteType() == "cms6" && process.env.NODE_ENV != "production") {
                domain = "heyville.org";
            }
            return domain;
        },
        apiKey() {
            let user = this.getCurrentUser();
            let site = localStorage.getObject("site");

            if (user.data.heygov_api_key) {
                return user.data.heygov_api_key;
            } else {
                return site.heygovApiKey;
            }
        },
    },
    mixins: [helperMixin, siteMixin, userMixin],
};
