export default {
	methods: {
		getSite: function() {
			return localStorage.getObject('site');
		},
		getSiteUrl: function() {
			if (!this.getSite()) {return;}
			return this.getSite().staging_url ?? this.getSite().url;
		},
		getSiteType: function() {
            let site = this.getSite();
			return site ? site.type : '';
		},
		getSiteRoutes: function() {
			if (!this.getSite()) {return;}
			return this.getSite().routes;
		},
		supportsAlerts() {
			if (!this.getSite()) {return;}
			return '/wp/v2/tw_alerts' in this.getSiteRoutes() || this.getSite().supportsAlerts;
		},
		supportsFBPublish() {
			if (!this.getSite()) {return;}
			return this.getSite().supportsFBPublish;
		},
		doesSupportDepartments() {
			if (!this.getSite()) {return;}
			return this.getSite().supportsDepartments;
		},
		supportsTWPageBuilder() {
			return ! this.getSite().bbActive;
		},
		validURL: function(value) {

				var re_weburl = new RegExp(
				"^" +
				// protocol identifier
				"(?:(?:https?|ftp)://)" +
				"(?:" +
				// IP address exclusion
				"(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
				"(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
				"(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
				"(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
				"(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
				"(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
				"|" +
				// host name
				"(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
				// domain name
				"(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
				// TLD identifier
				"(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
				// TLD may end with dot
				"\\.?" +
				")" +
				// port number
				"(?::\\d{2,5})?" +
				// resource path
				"(?:[/?#]\\S*)?" +
				"$", "i"
			);

			return re_weburl.test(value);
		},
		isHeyGovClient() {
			if (this.getSiteRoutes()) {
				return '/heygov/v1' in this.getSiteRoutes();
			}
		},
		isHeyGovClientWithVenues() {
			let siteRoutes = this.getSiteRoutes();
			return '/heygov/v1' in siteRoutes && '/heygov/v1/info' in siteRoutes;
		},
        getTimezone() {
            if (!this.getSite()) {return;}
			return this.getSite().timezone || "America/Chicago";
        }
	}
}
